import { graphql, Link } from 'gatsby'
import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'

import SiteNavLogo from '../components/header/SiteNavLogo'
import PostCard from '../components/PostCard'
import Wrapper from '../components/Wrapper'
import IndexLayout from '../layouts'
import { colors } from '../styles/colors'
import { inner, outer, PostFeed, SiteHeader } from '../styles/shared'

const SiteNavCenter = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .site-nav-logo {
    margin-right: 0;
  }
`

const ErrorTemplate = css`
  padding: 7vw 4vw;
`

const ErrorCode = styled.h1`
  margin: 0;
  font-size: 12vw;
  line-height: 1em;
  letter-spacing: -5px;
  opacity: 0.3;
`

const ErrorDescription = styled.p`
  margin: 0;
  color: ${colors.midgrey};
  font-size: 3rem;
  line-height: 1.3em;
  font-weight: 400;
`

const ErrorLink = css`
  display: inline-block;
  margin-top: 5px;
`

const NotFoundPage = ({ data }) => {
  const { edges } = data.allContentfulPost

  return (
    <IndexLayout>
      <Wrapper>
        <header css={[SiteHeader, outer]}>
          <div className="inner">
            <SiteNavCenter>
              <SiteNavLogo />
            </SiteNavCenter>
          </div>
        </header>
        <main id="site-main" css={[ErrorTemplate, outer]}>
          <div css={inner}>
            <section style={{ textAlign: 'center' }}>
              <ErrorCode>404</ErrorCode>
              <ErrorDescription>Page not found</ErrorDescription>
              <Link css={ErrorLink} to="/">
                Go to the front page →
              </Link>
            </section>
          </div>
        </main>
        <aside css={outer}>
          <div css={inner}>
            <div css={PostFeed}>
              {edges.map(({ node }) => (
                <PostCard key={node.slug} post={node} />
              ))}
            </div>
          </div>
        </aside>
      </Wrapper>
    </IndexLayout>
  )
}

NotFoundPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    allContentfulPost(limit: 3, sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          tags
          heroImage {
            fluid(maxWidth: 1800) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          body {
            childMarkdownRemark {
              excerpt
              timeToRead
            }
          }
          author {
            name
            image {
              fluid(maxWidth: 90) {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    }
  }
`
